// @flow

import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import * as radius from "src/styles/constants/radius";
import { Overlay as StyledOverlay } from "src/styles/modal";

const fade = keyframes`
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
`;

const Modal = styled.div`
  border-radius: ${radius.medium};
  background: ${colors.brightLight};
  padding: ${spacing.space_l};
  width: ${props => (props.isConversation ? "600px" : "24rem")};
  min-height: ${props => (props.isConversation ? "30rem" : "5.125rem")};
  max-height: ${props => (props.isConversation ? "30rem" : "25rem")};
  overflow: auto;
  animation: ${fade} 50ms ease-in;
  > div:nth-of-type(2) {
  }
`;

const Overlay = styled(StyledOverlay)`
  z-index: 995;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Modal, Overlay };
